function initActive() {
    const item = document.querySelector('[data-need-active]');
    const ulActive = document.querySelector('[data-ul-active]');
    const dropList = document.querySelector('[data-drop-list]');

    if (item) {
        item.addEventListener('mouseover', (e) => {
            if (!ulActive.classList.contains('_active')) {
                ulActive.classList.add('_active');
            } else {
                ulActive.classList.remove('_active');
            }
        });
    }
    if (dropList) {
        dropList.addEventListener('mouseleave', () => {
            if (ulActive.classList.contains('_active')) {
                ulActive.classList.remove('_active');
            }
        });
    }
    document.addEventListener('scroll', () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 50) {
            ulActive.classList.remove('_active');
        }
    })
}

document.addEventListener('DOMContentLoaded', initActive);
