import Jax from '../../components/jax/jax.js';

document.addEventListener('DOMContentLoaded', function () {
  const searchForm = document.querySelector('[data-mini-search-form]');
  if (searchForm) {
    let jax = new Jax(searchForm.getAttribute('action'), "GET");
    searchForm.addEventListener('submit', function (e) {
      e.preventDefault();
      jax.send(new FormData(searchForm)).then(function (pageData) {
        let container = document.createElement('div');
        container.innerHTML = pageData;
        let data = container.querySelector('[data-search-data]');
        let searchData = document.querySelector('[data-search-data]');
        searchData.parentNode.replaceChild(data, searchData);
      });
    });
  }
});
