document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {

    console.log($( "body" ).data( "cookie" ));

    if ($.cookie('agree') == null && $( "body" ).data( "cookie" )) {
      let cookieFrame = document.createElement('div');
      cookieFrame.classList.add('cookie-frame');

      let policyLink = document.querySelector('.footer__policy').getAttribute('href');

      let text = document.createElement('div');
      text.classList.add('cookie-frame__text');
      text.innerHTML = '<b>Этот сайт использует файлы cookie</b>. Продолжая использовать этот сайт, ' +
        'вы соглашаетесь на их использование. Для получения дополнительной информации, пожалуйста, ознакомьтесь с нашей ' +
        '<a href="' + policyLink + '">Политикой конфиденциальности.</a>';

      let button = document.createElement('a');
      button.setAttribute('href', 'javascript:void(0)');
      button.classList.add('button');
      button.classList.add('button_cyan');
      button.innerHTML = 'Принять';
      button.onclick = () => {
        document.body.removeChild(cookieFrame);
        $.cookie('agree', 'yes', { expires: 365 });
      }

      document.body.appendChild(cookieFrame);
      cookieFrame.appendChild(text);
      cookieFrame.appendChild(button);
    }
  }, 50);
});