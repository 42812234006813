import "inputmask/dist/inputmask/dependencyLibs/inputmask.dependencyLib.js";
import Inputmask from "inputmask/dist/inputmask/inputmask";
import Jax from "../../components/jax/jax.js";

function initEmailField() {
  document.querySelectorAll('[data-email-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = "true";
    let inputmask = new Inputmask({
      mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]',
      clearIncomplete: true,
      oncomplete: function () {
        if (this.dataset.leadUrl) {
          let jax = new Jax(this.dataset.leadUrl, 'POST');
          jax.send({
            email: this.value
          });
        }
      },
      greedy: false,
      onBeforePaste: function (pastedValue, opts) {
        pastedValue = pastedValue.toLowerCase();
        return pastedValue.replace("mailto:", "");
      },
      definitions: {
        '*': {
          validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
          casing: "lower"
        }
      }
    });
    inputmask.mask(input);
  });
}

document.addEventListener("DOMContentLoaded", initEmailField);
document.addEventListener("DOMContentMutated", initEmailField);