// скрывает степень родства, если заполняешь заявление на себя
const equalEl = document.querySelector('#TaxForm_equal');
if (equalEl) {
  function checkEqual() {
    const relationInput = document.querySelector('#tax-relation-input');

    if (equalEl.checked) {
      relationInput.classList.add('_hidden');
      document.querySelector('#TaxForm_patient_name').value = document.querySelector('#TaxForm_name').value;
      document.querySelector('#TaxForm_patient_dob').value = document.querySelector('#TaxForm_dob').value;
    } else {
      relationInput.classList.remove('_hidden');
    }
  }

  equalEl.addEventListener('change', () => {
    checkEqual();
  });
  checkEqual();
}

// скрывает список филиалов если не забираешь там справку лично
const method = document.querySelector('#method');
if (method) {
  const personally = method.querySelector('#TaxForm_method-Лично_в_клинике');
  const place = document.querySelector('.tax__input_place');
  function getPersonally() {
    if (personally.checked) {
      place.classList.remove('_hidden');
    } else {
      place.classList.add('_hidden');
    }
  }
  method.querySelectorAll('input[type=radio]').forEach((element) => {
    element.addEventListener('change', () => {
      getPersonally();
    })
  });
  getPersonally();
}
