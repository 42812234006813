import Jax from '../components/jax/jax.js';

document.addEventListener('DOMContentLoaded', () => {

    const medicalTermsFilterForm = document.querySelector('[data-medical-terms-form]');
    const branchSelect = document.querySelector('[data-medical-terms-branch]');

    if (medicalTermsFilterForm) {
        let jax = new Jax(diseasesFilterForm.getAttribute('action'), "GET");

        medicalTermsFilterForm.addEventListener('submit', (e) => {
            e.preventDefault();
            jax.send(new FormData(medicalTermsFilterForm)).then(function (pageData) {
                let container = document.createElement('DIV');
                container.innerHTML = pageData;
                let data = container.querySelector('[data-search-data]');
                let newData = document.querySelector('[data-search-data]');
                newData.parentNode.replaceChild(data, newData);
            });
        });

        if (branchSelect) {
            branchSelect.addEventListener('change', (e) => {
                medicalTermsFilterForm.dispatchEvent(new Event('submit'));
            });
        }
    }
});