import BviHideWidgets from "./bvi-hide-widgets";
import BviDataSelectorsKiller from "./bvi-data-selectors-killer";

class BviHelper {
    constructor() {
        this.body = document.body;
        this.widgets = undefined;
        this.dataSelectors = [];
        this.init();
        this.addListeners();
        this.checkOnLoaded();
    }

    init() {
        this.widgets = new BviHideWidgets();
        this.dataSelectors = [
            new BviDataSelectorsKiller('data-modal'),
            new BviDataSelectorsKiller('data-scroll-magic')
        ];
    }

    addListeners() {
        document.addEventListener('bvi-active', () => {
            this.widgets.setHidden();
            this.dataSelectors.forEach((dataSelector) => {
                dataSelector.removeDataSelector();
            });
        });

        document.addEventListener('bvi-disable', () => {
            this.widgets.setVisible();
            this.dataSelectors.forEach((dataSelector) => {
                dataSelector.addDataSelector();
            });
        });
    }

    checkOnLoaded() {
        if (this.body.classList.contains('bvi-active')) {
            this.widgets.setHidden();
            this.dataSelectors.forEach((dataSelector) => {
                dataSelector.removeDataSelector();
            });
        } else {
            this.widgets.setVisible();
            this.dataSelectors.forEach((dataSelector) => {
                dataSelector.addDataSelector();
            });
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    setInterval(() => {
        if (document.body.classList.contains('bvi-active')) {
            new BviHelper();
        }
    }, 2000);
});