import Jax from "../../components/jax/jax.js";

function getSpecialist() {
  document.querySelectorAll(`[name="RecallForm[branch]"]:not([data-initialized]`).forEach((input) => {
    input.dataset.initialized = "true";
    input.addEventListener('change', () => {
      const form = input.closest('form');
      const specialistInput = form.querySelector(`[name="RecallForm[doctor]"]`);
      const specialistInputValue = specialistInput.value;
      if (specialistInput) {
        specialistInput.innerHTML = '';
        let jax = new Jax('/request/get_specialist', 'POST', true);
        jax.send({
          id: input.value
        }).then((data) => {
          Object.entries(data).forEach((specialist) => {
            const specOption = document.createElement('option');
            specOption.value = specialist[0];
            specOption.innerHTML = specialist[1];
            specialistInput.appendChild(specOption);

            if (specialistInputValue === specOption.value) {
              specialistInput.value = specialistInputValue;
            }
          })
        });
      }
    });
  });
}

document.addEventListener("DOMContentLoaded", getSpecialist);
document.addEventListener("DOMContentMutated", getSpecialist);