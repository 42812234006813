import Jax from '../components/jax/jax.js';

document.addEventListener('DOMContentLoaded', () => {

    const diseasesFilterForm = document.querySelector('[data-diseases-form]');
    const branchSelect = document.querySelector('[data-disease-branch]');

    if (diseasesFilterForm) {
        let jax = new Jax(diseasesFilterForm.getAttribute('action'), "GET");

        diseasesFilterForm.addEventListener('submit', (e) => {
            e.preventDefault();
            jax.send(new FormData(diseasesFilterForm)).then(function (pageData) {
                let container = document.createElement('DIV');
                container.innerHTML = pageData;
                let data = container.querySelector('[data-search-data]');
                let newData = document.querySelector('[data-search-data]');
                newData.parentNode.replaceChild(data, newData);
            });
        });

        if (branchSelect) {
            branchSelect.addEventListener('change', (e) => {
                diseasesFilterForm.dispatchEvent(new Event('submit'));
            });
        }
    }
});