import "inputmask/dist/inputmask/dependencyLibs/inputmask.dependencyLib.js";
import Inputmask from "inputmask/dist/inputmask/inputmask";

function initPhoneField() {
  document.querySelectorAll('[data-phone-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = "true";
    let inputmask = new Inputmask({
      mask: '+7 (999) 999-99-99',
      clearIncomplete: true,
    });
    inputmask.mask(input);
  });
}

document.addEventListener("DOMContentLoaded", initPhoneField);
document.addEventListener("DOMContentMutated", initPhoneField);