require('../scss/app.scss');

window.noZensmooth = true;

require('./common/map.js');
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/cookies/jquery.cookie.js');
require('./common/cookies/cookies.js');

require('./accordion/initializator');

require('./slider.js');
require('./reviews-select.js');
require('./max-height.js');
require('./menu.js');
require('./faq.js');
require('./prices.js');
require('./print.js');
require('./field/branchField.js');
require('./totop.js');
require('./table.js');
require('./add-active.js');
require('./branch_tabs.js');
require('./directions-magic-scroll.js');
require('./diseases-filter-form.js');
require('./medical-terms-filter-form.js');
require('./search/mini-search-form');

require('./field/phone');
require('./field/passport');
require('./field/email');
require('./field/date');
require('../components/smart-tabs/smart-tabs');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('../components/smart-tabs/smart-tabs');

require('../components/bvi/css/bvi.css');
require('./bvi');
require('./bvi-helper');
require('./tax');