class BviHideWidgets {
    constructor() {
        this.jivoElem = document.querySelector('jdiv');
        this.streamWoodButton = document.querySelector('.stream-wood-recall-btn')
        this.widgets = [this.jivoElem, this.streamWoodButton];
    }

    setVisible() {
        this.widgets.forEach((widget) => {
            if (widget) {
                widget.style.display = 'block';
            }
        });
    }

    setHidden() {
        this.widgets.forEach((widget) => {
            if (widget) {
                widget.style.display = 'none';
            }
        });
    }
}

export default BviHideWidgets;