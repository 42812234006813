import slick from "slick-carousel";

let $sliderMed = $('.block-spec__specs');
let $sliderInBranch = $('.branch-main__specs');
let $sliderActionsText = $('[data-actions-text]');
let $sliderActionsImage = $('[data-actions-image]');
let $sliderAboutImages = $('[data-about-images]');
let $sliderAboutVideo = $('[data-about-video]');
let $sliderAdvantages = $('[data-advantages-slider]');
let $sliderArticles = $('[data-artickles-slider]');
let $sliderBranchMedia = $('[data-branch-media]');
let $sliderAboutAdvantages = $('[data-about-advantages]');
let $sliderBranchWhatTreat = $('[data-branch-what-treat]');
let $sliderAdvantageEq = $('[data-equipment-images]');
let $sliderComplex = $('[data-complex-images]');
let $sliderService = $('[data-service-images]');
let $sliderControl = $('[data-control-images]');
let $sliderPaper = $('[data-paper-slider]');

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        // никаких слайдеров для слабовидящих
        if (!document.querySelector("body").classList.contains("bvi-active")) {
            if ($sliderArticles.length > 0) {
                const $status = $('[data-count-slide]');
                $sliderArticles.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $status.text(i + ' / ' + slick.slideCount);
                });
                $sliderArticles.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    draggable: false,
                    arrows: false,
                    infinite: true,
                });

                $('[data-article-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('article-arrow') === 'left') {
                        $sliderArticles.slick('slickPrev');
                    } else if ($(this).data('article-arrow') === 'right') {
                        $sliderArticles.slick('slickNext');
                    }
                });
            }

            if ($sliderAdvantages.length < 1280) {
                $sliderAdvantages.slick({
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    variableWidth: true,
                    dots: true,
                    dotsClass: 'slick-dots slider__dots',
                    arrows: false,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 375,
                            settings: {
                                speed: 500
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                speed: 500
                            }
                        },
                        {
                            breakpoint: 1280,
                            settings: "unslick"
                        },
                        {
                            breakpoint: 9000,
                            settings: "unslick"
                        }
                    ]
                });
            }

            if ($sliderAboutVideo.length > 0) {
                $sliderAboutVideo.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    infinite: true,
                });

                $('[data-about-video-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('about-video-arrow') === 'left') {
                        $sliderAboutVideo.slick('slickPrev');
                    } else if ($(this).data('about-video-arrow') === 'right') {
                        $sliderAboutVideo.slick('slickNext');
                    }
                });
            }

            if ($sliderAboutImages.length > 0) {
                $sliderAboutImages.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    infinite: true,
                    centerMode: true,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 1280,
                            settings: {
                                centerPadding: '136px',
                                variableWidth: false,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                centerPadding: '30px',
                                variableWidth: false,
                            }
                        }
                    ]
                });

                $('[data-about-image-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('about-image-arrow') === 'left') {
                        $sliderAboutImages.slick('slickPrev');
                    } else if ($(this).data('about-image-arrow') === 'right') {
                        $sliderAboutImages.slick('slickNext');
                    }
                });
            }

            if ($sliderActionsImage.length > 0) {
                var $status = $('[data-count-slide]');
                $sliderActionsImage.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $status.text(i + ' / ' + slick.slideCount);
                });
                $sliderActionsImage.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    draggable: false,
                    arrows: false,
                    infinite: true,
                    asNavFor: $sliderActionsText,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                draggable: false,
                            }
                        }
                    ]
                });

                $('[data-action-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('action-arrow') == 'left') {
                        $sliderActionsImage.slick('slickPrev');
                    } else if ($(this).data('action-arrow') == 'right') {
                        $sliderActionsImage.slick('slickNext');
                    }
                });
            }

            if ($sliderActionsText.length > 0) {
                $sliderActionsText.slick({
                    slidesToShow: 1,
                    arrows: false,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    draggable: false,
                    asNavFor: $sliderActionsImage,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                draggable: false,
                            }
                        }
                    ],
                });

                $('[data-action-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('action-arrow') == 'left') {
                        $sliderActionsText.slick('slickPrev');
                    } else if ($(this).data('action-arrow') == 'right') {
                        $sliderActionsText.slick('slickNext');
                    }
                });
            }

            if ($sliderMed.length > 0) {
                $sliderMed.slick({
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                infinite: true,
                                speed: 300,
                                slidesToShow: 2,
                                dots: true,

                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                    ]
                });

                $('[data-slider-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('slider-arrow') == 'left') {
                        $sliderMed.slick('slickPrev');
                    } else if ($(this).data('slider-arrow') == 'right') {
                        $sliderMed.slick('slickNext');
                    }
                });
            }

            if ($sliderInBranch.length > 0) {
                $sliderInBranch.slick({
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                dots: false,
                                arrows: false,
                                infinite: true,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: false,
                                dots: true,
                                arrows: false,
                                infinite: true,
                            }
                        }
                    ]
                });

                $('[data-slider-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('slider-arrow') == 'left') {
                        $sliderInBranch.slick('slickPrev');
                    } else if ($(this).data('slider-arrow') == 'right') {
                        $sliderInBranch.slick('slickNext');
                    }
                });
            }

            if ($sliderBranchMedia.children().length > 1) {
                $sliderBranchMedia.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    infinite: true,
                });

                $('[data-branch-media-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('branch-media-arrow') === 'left') {
                        $sliderBranchMedia.slick('slickPrev');
                    } else if ($(this).data('branch-media-arrow') === 'right') {
                        $sliderBranchMedia.slick('slickNext');
                    }
                });
            }

            if ($sliderAboutAdvantages.children().length > 1) {
                $sliderAboutAdvantages.slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                    dotsClass: 'about-page-advantage__dots slick-dots slider__dots',
                    arrows: false,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,

                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 2,
                                variableWidth: true,
                            }
                        },
                    ]
                });

                $('[data-about-media-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('about-media-arrow') === 'left') {
                        $sliderAboutAdvantages.slick('slickPrev');
                    } else if ($(this).data('about-media-arrow') === 'right') {
                        $sliderAboutAdvantages.slick('slickNext');
                    }
                });
            }

            if ($sliderBranchWhatTreat.length > 0) {
                $sliderBranchWhatTreat.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    infinite: true,
                });

                $('[data-branch-media-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('branch-media-arrow') === 'left') {
                        $sliderBranchWhatTreat.slick('slickPrev');
                    } else if ($(this).data('branch-media-arrow') === 'right') {
                        $sliderBranchWhatTreat.slick('slickNext');
                    }
                });

                document.addEventListener('smart-tabs-handle', () => {
                    $sliderBranchWhatTreat.slick('refresh');
                })
            }

            if ($sliderPaper.length > 0) {
                $sliderPaper.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    infinite: true,
                });

                $('[data-branch-media-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('branch-media-arrow') === 'left') {
                        $sliderPaper.slick('slickPrev');
                    } else if ($(this).data('branch-media-arrow') === 'right') {
                        $sliderPaper.slick('slickNext');
                    }
                });
            }

            if ($sliderAdvantageEq.length > 0) {
                $sliderAdvantageEq.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    infinite: true,
                });

                $('[data-equipment-image-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('equipment-image-arrow') === 'left') {
                        $sliderAdvantageEq.slick('slickPrev');
                    } else if ($(this).data('equipment-image-arrow') === 'right') {
                        $sliderAdvantageEq.slick('slickNext');
                    }
                });
            }

            if ($sliderComplex.length > 0) {
                $sliderComplex.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    infinite: true,
                });

                $('[data-complex-image-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('complex-image-arrow') === 'left') {
                        $sliderComplex.slick('slickPrev');
                    } else if ($(this).data('complex-image-arrow') === 'right') {
                        $sliderComplex.slick('slickNext');
                    }
                });
            }

            if ($sliderService.length > 0) {
                $sliderService.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    infinite: true,
                });

                $('[data-service-image-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('service-image-arrow') === 'left') {
                        $sliderService.slick('slickPrev');
                    } else if ($(this).data('service-image-arrow') === 'right') {
                        $sliderService.slick('slickNext');
                    }
                });
            }

            if ($sliderControl.length > 0) {
                $sliderControl.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    infinite: true,
                });

                $('[data-control-image-arrow]').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).data('control-image-arrow') === 'left') {
                        $sliderControl.slick('slickPrev');
                    } else if ($(this).data('control-image-arrow') === 'right') {
                        $sliderControl.slick('slickNext');
                    }
                });
            }
        }
    }, 20);
});
