// data-append-price

import Jax from '../components/jax/jax.js';

document.addEventListener('DOMContentLoaded', function () {

  document.querySelectorAll('[data-append-price]').forEach((faqForm) => {
    let jax = new Jax(faqForm.getAttribute('action'), "POST");
    faqForm.addEventListener('submit', function (e) {
      e.preventDefault();
      jax.send(new FormData(faqForm)).then(function (pageData) {
        let num = faqForm.dataset.appendPrice;
        updatePrices(num);
      });
    });
  });

  function updatePrices(num) {
    let jax = new Jax(window.location.href, "GET");
    jax.send().then(function (pageData) {
      let container = document.createElement('DIV');
      container.innerHTML = pageData;
      let data = container.querySelector('[data-prices="'+num+'"]');
      let faqData = document.querySelector('[data-prices="'+num+'"]');
      faqData.parentNode.replaceChild(data, faqData);
    });
  }
});