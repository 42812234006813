const initMaxHeightBlocks = () => {
    const maxHeightBlocks = document.querySelectorAll('[data-max-height]');

    maxHeightBlocks.forEach((maxHeightBlock) => {
        const height = maxHeightBlock.querySelector('[data-max-height-measure]').offsetHeight;
        const maxHeight = parseInt(maxHeightBlock.dataset.maxHeight, 10);
        // console.log(height, maxHeight);

        maxHeightBlock.classList.remove('_max-height-exceeded');
        if (height > maxHeight) {
            maxHeightBlock.classList.add('_max-height-exceeded');
        }
    });
};

window.addEventListener('resize', initMaxHeightBlocks);
document.addEventListener('DOMContentLoaded', initMaxHeightBlocks);