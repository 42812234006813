import Jax from '../components/jax/jax.js';

document.addEventListener('DOMContentLoaded', function () {

  const faqForm = document.querySelector('[data-faq-search-form]');
  if (faqForm) {
    let jax = new Jax(faqForm.getAttribute('action'), "GET");

    faqForm.addEventListener('submit', function (e) {
      e.preventDefault();
      jax.send(new FormData(faqForm)).then(function (pageData) {
        let container = document.createElement('DIV');
        container.innerHTML = pageData;
        let data = container.querySelector('[data-search-data]');
        let faqData = document.querySelector('[data-search-data]');
        faqData.parentNode.replaceChild(data, faqData);
      });
    });

    faqForm.querySelectorAll('input[type=checkbox]').forEach((item) => {
      item.addEventListener('change', function () {
        faqForm.dispatchEvent(new CustomEvent('submit'))
      })
    })
  }

});
