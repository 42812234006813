$(window).on('scroll',function(){
    var top = $(document).scrollTop();

    if (top > 400){
        if (!$('.totop').hasClass('show')){
            $('.totop').addClass('show');
        }
    }else{
        if ($('.totop').hasClass('show')){
            $('.totop').removeClass('show');
        }
    }
});

$('.totop').on('click',function(){
    $('html, body').animate({
        scrollTop: 0
    },400);
});