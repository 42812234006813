class BviDataSelectorsKiller {
    constructor(selector) {
        this.selector = selector;
        this.elements = document.querySelectorAll('[' + this.selector + ']');
    }

    removeDataSelector() {
        this.elements.forEach((element) => {
            if (element.hasAttribute(this.selector)) {
                element.removeAttribute(this.selector);
            }
        });
    }

    addDataSelector() {
        this.elements.forEach((element) => {
            if (!element.hasAttribute(this.selector)) {
                element.setAttribute(this.selector, '');
            }
        });
    }
}

export default BviDataSelectorsKiller;