import "inputmask/dist/inputmask/dependencyLibs/inputmask.dependencyLib.js";
import Inputmask from "inputmask/dist/inputmask/inputmask";
import Jax from "../../components/jax/jax.js";

const initDateField = () => {
  const inputs = document.querySelectorAll('input[type=date]');
  inputs.forEach((input) => {
    if (input.type !== 'date') {
      let inputmask = new Inputmask({
        mask: '99.99.9999',
        clearIncomplete: true,
      });
      inputmask.mask(input);
    }
  });
};

document.addEventListener("DOMContentLoaded", initDateField);
document.addEventListener("DOMContentMutated", initDateField);
