function initMapYandex() {
  setBviBuiltActive();

  document.querySelectorAll('[data-map]').forEach(function initMapElement(mapElement) {
    if (document.body.classList.contains('bvi-active')) {
      mapElement.dataset.zoom = '18';
    }
    
    let myMap = new ymaps.Map(mapElement, {
      center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
      zoom: mapElement.dataset.zoom,
      controls: []
    }, {
      searchControlProvider: 'yandex#search'
    });

    const collection = new ymaps.GeoObjectCollection(null, {
      // Запретим появление балуна.
      hasBalloon: false,
    });

    const filials = document.querySelectorAll('[data-filial]');

    filials.forEach((filial) => {
      collection.add(new ymaps.Placemark([filial.dataset.lat, filial.dataset.lng], {}, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: mapElement.dataset.mark,
        // Размеры метки.
        iconImageSize: [39, 53],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [0, 0],
      }));

      filial.addEventListener('click', () => {
        filials.forEach((el) => {
          el.classList.remove('_active')
        });
        filial.classList.add('_active');
        myMap.setCenter([parseFloat(filial.dataset.lat), parseFloat(filial.dataset.lng)]);
      });
    });

    myMap.geoObjects.add(collection);

    myMap.behaviors.disable('scrollZoom');
    if (window.innerWidth <= 1024) {
      myMap.behaviors.disable('drag');
    }
  });

  setTimeout(() => {
    setBviNoStyles();
  }, 2000)
}

function setBviBuiltActive() {
  let bviBody = document.querySelector(".bvi-body")
  if (bviBody) {
    bviBody.dataset.bviBuiltelements = 'true';
  }
}

function setBviNoStyles() {
  document.querySelectorAll('ymaps').forEach((item) => {
    item.classList.add('bvi-no-styles');
    item.classList.add('bvi-no-font-size');

    item.querySelectorAll('a').forEach((linkItem) => {
      linkItem.classList.add('bvi-no-styles');
      linkItem.classList.add('bvi-no-font-size');
    })
  })
}

window.addEventListener('load', () => {
  if (window.ymaps) {
    ymaps.ready(initMapYandex);
  }
});
